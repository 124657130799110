// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBmnaVux5wTCsFpDwyv43s4ePum0qxKZx8",
    authDomain: "padel-scorer.firebaseapp.com",
    databaseURL: "https://padel-scorer-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "padel-scorer",
    storageBucket: "padel-scorer.appspot.com",
    messagingSenderId: "951022833358",
    appId: "1:951022833358:web:7e906971b8715ff9c98a9f"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const database = getFirestore(); 

const auth = getAuth();