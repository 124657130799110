import {
  Dots,
  Levels,
  Sentry,
  Spinner,
  Squares,
  Digital,
  Bounce,
  Windmill,
} from "react-activity";

export const GetRandomActivityIndicator = () => {
  var loader = Math.floor(Math.random() * (8 - 1 + 1) + 1);
  switch (loader) {
    case 1:
      return <Dots color="green" />;
    case 2:
      return <Levels color="green" />;
    case 3:
      return <Sentry color="green" />;
    case 4:
      return <Spinner color="green" />;
    case 5:
      return <Squares color="green" />;
    case 6:
      return <Digital color="green"/>;
    case 7:
      return <Bounce color="green"/>;
    case 8:
      return <Windmill color="green"/>;
    default:
      return <Dots color="green"/>;
  }
};
