import { useEffect, useState } from "react";
import "./App.css";
import { database } from "./config/fb.js";
import { collection, getDocs } from "firebase/firestore";
import { GetRandomActivityIndicator } from "./logic/controllers";
//import { Dots } from "react-activity";
import {
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  DomainVerification,
  FormatListBulletedRounded,
  Group,
  Person,
} from "@mui/icons-material";
import GraficoInfo from "./components/GraficoInfo";
const queryParams = new URLSearchParams(window.location.search);
const matchId = queryParams.get("matchId");

if(matchId!=null) document.title = "Cargando...";

function App() {
  const [partida, setPartida] = useState([]);
  const [infoMatch, setInfoMatch] = useState([]);
  const [dataType, setDataType] = useState(false);
  const [tipoJugadores, setTipoJugadores] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const getMatchData = async () => {
      const q = collection(database, `Partidas/${matchId}/PartidoCompleto`);
      const querySnapshot = await getDocs(q);
      let equipos = {};
      let sets = {};
      let normas = {};
      let setsData = [];
      querySnapshot.forEach(async (match) => {
        if (match.data().infoequipos !== undefined){
          equipos = match.data().infoequipos;
          document.title= `${match.data().infoequipos.equipo1.nombre} vs. ${match.data().infoequipos.equipo2.nombre}`;
        }
        if (match.data().normas !== undefined) normas = match.data().normas;
        if (match.data().set !== undefined) sets = match.data().set;
        if (match.data().infoSets !== undefined)
          setsData = match.data().infoSets;
      });

      setPartida([equipos, sets, setsData, normas]);
    };

    if(matchId!=null) getMatchData();

    GetRandomActivityIndicator();
  }, []);

  return matchId === null ? (
    <div className="App">
      <h1>NO HAY ID</h1>
    </div>
  ) : partida.length !== 0 ? (
    <div className="App">
      <div className="teams">
        <div className="team Titles">
          <p>Players</p>
          <p>Score</p>
        </div>
        <div className="team">
          <div className="teamPeople">
            <h3 className="teamName">
              {partida[0].equipo1.jugadores.jugador1.nombre}
            </h3>
            <h3 className="teamName">
              {partida[0].equipo1.jugadores.jugador2.nombre}
            </h3>
          </div>
          <div className="teamSets">
            {partida[2].map((item, index) => {
              return <h3 className="setGames">{item.equipo1}</h3>;
            })}
          </div>
        </div>
        <div className="team">
          <div className="teamPeople">
            <h3 className="teamName">
              {partida[0].equipo2.jugadores.jugador1.nombre}
            </h3>
            <h3 className="teamName">
              {partida[0].equipo2.jugadores.jugador2.nombre}
            </h3>
          </div>
          <div className="teamSets">
            {partida[2].map((item, index) => {
              return <h3 className="setGames">{item.equipo2}</h3>;
            })}
          </div>
        </div>
      </div>
      <div className="scoreDetails">
        <ButtonGroup
          size="large"
          style={{ width: "100%", boxShadow: "none" }}
          variant="outlined"
          color="secondary"
          aria-label="outlined secondary button group"
        >
          <Button
            onClick={() => setValue(0)}
            fullWidth={true}
            style={{
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              textTransform: "none",
            }}
          >
            Partida
          </Button>
          {Object.keys(partida[1]).map((item, index) => {
            return (
              <Button
                onClick={() => setValue(index + 1)}
                style={
                  index + 1 === Object.keys(partida[1]).length
                    ? {
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        textTransform: "none",
                      }
                    : { textTransform: "none" }
                }
                fullWidth={true}
              >
                Set {index + 1}
              </Button>
            );
          })}
        </ButtonGroup>
        <div className="ordenarPor" style={dataType===false ? {justifyContent: 'flex-end'} : null}>
          {dataType !== false ? (
            <div>
              <Chip
                style={{marginRight: "10px"}}
                label="De Revés"
                onClick={() => setTipoJugadores(true)}
                deleteIcon={<DomainVerification />}
              />
              <Chip label="De Drive" onClick={() => setTipoJugadores(false)} />
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p>Ordenar por: </p>
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ paddingBottom: "4px" }}
            >
              <FormatListBulletedRounded />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
                onClick={() => {
                  setDataType(false);
                  handleClose();
                }}
              >
                Equipos <Group />{" "}
              </MenuItem>
              <MenuItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
                onClick={() => {
                  setDataType(true);
                  handleClose();
                }}
              >
                Jugadores <Person />
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="players">
          {dataType===false ? (
            <div>
              <p className="teamName">
                {partida[0].equipo1.jugadores.jugador1.nombre}
              </p>
              <p className="teamName">
                {partida[0].equipo1.jugadores.jugador2.nombre}
              </p>
            </div>
            ) : tipoJugadores=== false ? (
              partida[0].equipo1.position === false ? (
                <p className="teamName">
                  {partida[0].equipo1.jugadores.jugador2.nombre}
                </p>
              ) : (
                <p className="teamName">
                  {partida[0].equipo1.jugadores.jugador1.nombre}
                </p>
              )
            ) : partida[0].equipo1.position === true ? (
              <p className="teamName">
                {partida[0].equipo1.jugadores.jugador2.nombre}
              </p>
            ) : (            
            <p className="teamName">
              {partida[0].equipo1.jugadores.jugador1.nombre}
            </p>)
          }
          <div className="setsDetails">
            {partida[2].map((item, index) => {
              return (
                <h2 style={{ marginLeft: "10px" }}>
                  {item.equipo1}-{item.equipo2}{" "}
                </h2>
              );
            })}
          </div>
          
          {dataType===false ? (
            <div>
              <p style={{ textAlign: "right" }} className="teamName">
                {partida[0].equipo2.jugadores.jugador1.nombre}
              </p>
              <p style={{ textAlign: "right" }} className="teamName">
                {partida[0].equipo2.jugadores.jugador2.nombre}
              </p>
            </div>
            ) : tipoJugadores=== false ? (
              partida[0].equipo2.position === false ? (
                <p style={{ textAlign: "right" }} className="teamName">
                  {partida[0].equipo2.jugadores.jugador2.nombre}
                </p>
              ) : (
                <p style={{ textAlign: "right" }} className="teamName">
                  {partida[0].equipo2.jugadores.jugador1.nombre}
                </p>
              )
            ) : partida[0].equipo2.position === true ? (
              <p style={{ textAlign: "right" }} className="teamName">
                {partida[0].equipo2.jugadores.jugador2.nombre}
              </p>
            ) : (            
            <p style={{ textAlign: "right" }} className="teamName">
              {partida[0].equipo2.jugadores.jugador1.nombre}
            </p>)
          }
        </div>
        <GraficoInfo
          matchData={partida}
          dataType={dataType}
          tipoJugadores={tipoJugadores}
          value={value}
          matchFunc={setInfoMatch}
          match={infoMatch}
        />
      </div>
    </div>
  ) : (
    <div className="AppLoad">
      <CircularProgress size={75} color="secondary" />
    </div>
  );
}

export default App;
