import { useEffect } from "react";
import "./graficoinfostyles.css";

function GraficoInfo({
  matchData,
  dataType,
  tipoJugadores,
  value,
  matchFunc,
  match,
}) {
  const infoSets = matchData[1];
  const infoEquipo1 = matchData[0].equipo1;
  const infoEquipo2 = matchData[0].equipo2;

  const getAllMatchData = (dataType) => {
    let partidaEntera;
    if(!dataType){
      partidaEntera =[
        {
          name: "Break Points",
          equipo1: [0, 0],
          equipo2: [0, 0],
        },
        {
          name: "Puntos de Oro",
          equipo1: 0,
          equipo2: 0,
        },
        {
          name: "Winners",
          equipo1: 0 + 0,
          equipo2: 0 + 0,
        },
        {
          name: "Smashes",
          equipo1: 0 + 0,
          equipo2: 0 + 0,
        },
        {
          name: "Unforced Errors",
          equipo1: 0 + 0,
          equipo2: 0 + 0,
        },
      ];
    }
    else{
      partidaEntera =[
        {
          name: "Winners",
          equipo1: 0,
          equipo2: 0,
        },
        {
          name: "Smashes",
          equipo1: 0,
          equipo2: 0,
        },
        {
          name: "Unforced Errors",
          equipo1: 0,
          equipo2: 0,
        },
      ];
    }
    for (let i = 1; i <= Object.keys(infoSets).length; i++) {
      if(!dataType){
        partidaEntera=[
          {
            name: "Break Points",
            equipo1: partidaEntera[0].equipo1=[partidaEntera[0].equipo1[0]+infoSets["set" + i].datosJugadores.equipo1.breakPointsExito, partidaEntera[0].equipo1[1]+infoSets["set" + i].datosJugadores.equipo1.breakPoints],
            equipo2: partidaEntera[0].equipo2=[partidaEntera[0].equipo2[0]+infoSets["set" + i].datosJugadores.equipo2.breakPointsExito, partidaEntera[0].equipo2[1]+infoSets["set" + i].datosJugadores.equipo2.breakPoints], },
          {
            name: "Puntos de Oro",
            equipo1: partidaEntera[1].equipo1=partidaEntera[1].equipo1+infoSets["set" + i].datosJugadores.equipo1.puntosOro,
            equipo2: partidaEntera[1].equipo2=partidaEntera[1].equipo2+infoSets["set" + i].datosJugadores.equipo2.puntosOro,
          },
          {
            name: "Winners",
            equipo1: partidaEntera[2].equipo1= partidaEntera[2].equipo1+(infoSets["set" + i].datosJugadores.equipo1.jugador1.winners + infoSets["set" + i].datosJugadores.equipo1.jugador2.winners),
            equipo2: partidaEntera[2].equipo2= partidaEntera[2].equipo2+(infoSets["set" + i].datosJugadores.equipo2.jugador1.winners + infoSets["set" + i].datosJugadores.equipo2.jugador2.winners),
          },
          {
            name: "Smashes",
            equipo1: partidaEntera[3].equipo1= partidaEntera[3].equipo1+(infoSets["set" + i].datosJugadores.equipo1.jugador1.smashesExito + infoSets["set" + i].datosJugadores.equipo1.jugador2.smashesExito),
            equipo2: partidaEntera[3].equipo2= partidaEntera[3].equipo2+(infoSets["set" + i].datosJugadores.equipo2.jugador1.smashesExito + infoSets["set" + i].datosJugadores.equipo2.jugador2.smashesExito),
          },
          {
            name: "Unforced Errors",
            equipo1: partidaEntera[4].equipo1= partidaEntera[4].equipo1+(infoSets["set" + i].datosJugadores.equipo1.jugador1.unfError + infoSets["set" + i].datosJugadores.equipo1.jugador2.unfError),
            equipo2: partidaEntera[4].equipo2= partidaEntera[4].equipo2+(infoSets["set" + i].datosJugadores.equipo2.jugador1.unfError + infoSets["set" + i].datosJugadores.equipo2.jugador2.unfError),
          },
        ]
      }
      else{
        partidaEntera=[
          {
            name: "Winners",
            equipo1: partidaEntera[0].equipo1= partidaEntera[0].equipo1+(tipoJugadores === false
            ? infoEquipo1.position === false
              ? infoSets["set"+i].datosJugadores.equipo1.jugador2.winners
              : infoSets["set"+i].datosJugadores.equipo1.jugador1.winners
            : infoEquipo1.position === true
              ? infoSets["set"+i].datosJugadores.equipo1.jugador2.winners
              : infoSets["set"+i].datosJugadores.equipo1.jugador1.winners),
            equipo2: partidaEntera[0].equipo2= partidaEntera[0].equipo2+(tipoJugadores === false
              ? infoEquipo2.position === false
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.winners
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.winners
              : infoEquipo2.position === true
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.winners
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.winners),
            },
          {
            name: "Smashes",
            equipo1: partidaEntera[1].equipo1= partidaEntera[1].equipo1+(tipoJugadores === false
              ? infoEquipo1.position === false
                ? infoSets["set"+i].datosJugadores.equipo1.jugador2.smashesExito
                : infoSets["set"+i].datosJugadores.equipo1.jugador1.smashesExito
              : infoEquipo1.position === true
                ? infoSets["set"+i].datosJugadores.equipo1.jugador2.smashesExito
                : infoSets["set"+i].datosJugadores.equipo1.jugador1.smashesExito),
            equipo2: partidaEntera[1].equipo2= partidaEntera[1].equipo2+(tipoJugadores === false
              ? infoEquipo2.position === false
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.smashesExito
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.smashesExito
              : infoEquipo2.position === true
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.smashesExito
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.smashesExito),
          },
          {
            name: "Unforced Errors",
            equipo1: partidaEntera[2].equipo1= partidaEntera[2].equipo1+(tipoJugadores === false
              ? infoEquipo1.position === false
                ? infoSets["set"+i].datosJugadores.equipo1.jugador2.unfError
                : infoSets["set"+i].datosJugadores.equipo1.jugador1.unfError
              : infoEquipo1.position === true
                ? infoSets["set"+i].datosJugadores.equipo1.jugador2.unfError
                : infoSets["set"+i].datosJugadores.equipo1.jugador1.unfError),
            equipo2: partidaEntera[2].equipo2= partidaEntera[2].equipo2+(tipoJugadores === false
              ? infoEquipo2.position === false
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.unfError
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.unfError
              : infoEquipo2.position === true
                ? infoSets["set"+i].datosJugadores.equipo2.jugador2.unfError
                : infoSets["set"+i].datosJugadores.equipo2.jugador1.unfError),  
          },
        ]
      }
    }
    return partidaEntera;
  }

  useEffect(() => {
    if (dataType === false && value === 0) {
      matchFunc(getAllMatchData(dataType));
    }
    if (dataType === true && value === 0) {
      matchFunc(getAllMatchData(dataType));
    }
    // for (let i = 1; i <= Object.keys(infoSets).length; i++) {
      if (dataType === false && value > 0) {
        matchFunc([
          {
            name: "Break Points",
            equipo1: [
              infoSets["set" + value].datosJugadores.equipo1.breakPointsExito,
              infoSets["set" + value].datosJugadores.equipo1.breakPoints,
            ],

            equipo2: [
              infoSets["set" + value].datosJugadores.equipo2.breakPointsExito,
              infoSets["set" + value].datosJugadores.equipo2.breakPoints,
            ],
          },
          {
            name: "Puntos de Oro",
            equipo1: infoSets["set" + value].datosJugadores.equipo1.puntosOro,
            equipo2: infoSets["set" + value].datosJugadores.equipo2.puntosOro,
          },
          {
            name: "Winners",
            equipo1:
              infoSets["set" + value].datosJugadores.equipo1.jugador1.winners +
              infoSets["set" + value].datosJugadores.equipo1.jugador2.winners,

            equipo2:
              infoSets["set" + value].datosJugadores.equipo2.jugador1.winners +
              infoSets["set" + value].datosJugadores.equipo2.jugador2.winners,
          },
          {
            name: "Smashes",
            equipo1:
              infoSets["set" + value].datosJugadores.equipo1.jugador1
                .smashesExito +
              infoSets["set" + value].datosJugadores.equipo1.jugador2
                .smashesExito,

            equipo2:
              infoSets["set" + value].datosJugadores.equipo2.jugador1
                .smashesExito +
              infoSets["set" + value].datosJugadores.equipo2.jugador2
                .smashesExito,
          },
          {
            name: "Unforced Errors",
            equipo1:
              infoSets["set" + value].datosJugadores.equipo1.jugador1.unfError +
              infoSets["set" + value].datosJugadores.equipo1.jugador2.unfError,

            equipo2:
              infoSets["set" + value].datosJugadores.equipo2.jugador1.unfError +
              infoSets["set" + value].datosJugadores.equipo2.jugador2.unfError,
          },
        ]);
      }
      if (dataType === true && value > 0) {
        matchFunc([
          {
            name: "Winners",
            equipo1:
              tipoJugadores === false
                ? infoEquipo1.position === false
                  ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                      .winners
                  : infoSets["set" + value].datosJugadores.equipo1.jugador1
                      .winners
                : infoEquipo1.position === true
                ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                    .winners
                : infoSets["set" + value].datosJugadores.equipo1.jugador1
                    .winners,

            equipo2:
              tipoJugadores === false
                ? infoEquipo2.position === false
                  ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                      .winners
                  : infoSets["set" + value].datosJugadores.equipo2.jugador1
                      .winners
                : infoEquipo2.position === true
                ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                    .winners
                : infoSets["set" + value].datosJugadores.equipo2.jugador1
                    .winners,
          },
          {
            name: "Smashes",
            equipo1:
              tipoJugadores === false
                ? infoEquipo1.position === false
                  ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                      .smashesExito
                  : infoSets["set" + value].datosJugadores.equipo1.jugador1
                      .smashesExito
                : infoEquipo1.position === true
                ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                    .smashesExito
                : infoSets["set" + value].datosJugadores.equipo1.jugador1
                    .smashesExito,

            equipo2:
              tipoJugadores === false
                ? infoEquipo2.position === false
                  ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                      .smashesExito
                  : infoSets["set" + value].datosJugadores.equipo2.jugador1
                      .smashesExito
                : infoEquipo2.position === true
                ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                    .smashesExito
                : infoSets["set" + value].datosJugadores.equipo2.jugador1
                    .smashesExito,
          },
          {
            name: "Unforced Errors",
            equipo1:
              tipoJugadores === false
                ? infoEquipo1.position === false
                  ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                      .unfError
                  : infoSets["set" + value].datosJugadores.equipo1.jugador1
                      .unfError
                : infoEquipo1.position === true
                ? infoSets["set" + value].datosJugadores.equipo1.jugador2
                    .unfError
                : infoSets["set" + value].datosJugadores.equipo1.jugador1
                    .unfError,

            equipo2:
              tipoJugadores === false
                ? infoEquipo2.position === false
                  ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                      .unfError
                  : infoSets["set" + value].datosJugadores.equipo2.jugador1
                      .unfError
                : infoEquipo2.position === true
                ? infoSets["set" + value].datosJugadores.equipo2.jugador2
                    .unfError
                : infoSets["set" + value].datosJugadores.equipo2.jugador1
                    .unfError,
          },
        ]);
      }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tipoJugadores, dataType]);


  return (
    <div className="graficos">
      {match.map((item, index) => {
        return item.name === "Break Points" ? (
          <div className="grafico">
            <p className="graphTypeName">{item.name}</p>
            <div className="graficoE1">
              <p className="value">
                {item.equipo1[0]}/{item.equipo1[1]}
                <div style={{
                  width:
                    item.equipo1[1] === 0
                      ? "0%"
                      : (item.equipo1[1] * 100) /
                          (item.equipo1[1] + item.equipo2[1]) +
                        "%",
                  borderTopRightRadius:
                    item.equipo1[1] === 0
                      ? 0
                      : (item.equipo1[1] * 100) /
                        (item.equipo1[1] + item.equipo2[1]) /
                        5,
                  borderBottomRightRadius:
                    item.equipo1[1] === 0
                      ? 0
                      : (item.equipo1[1] * 100) /
                        (item.equipo1[1] + item.equipo2[1]) /
                        5,
                  backgroundColor: "#569D56",
                }} className="visual">
                  <div
                  style={{
                    transition: "0.3s cubic-bezier(0.79, 0, 0.28, 1)",
                    height: "100%",
                    borderTopRightRadius: 
                        item.equipo1[1] === 0
                          ? 0
                          : (100 * item.equipo1[0]) / item.equipo1[1] / 7,

                    borderBottomRightRadius:
                        item.equipo1[1] === 0
                          ? 0
                          : (100 * item.equipo1[0]) / item.equipo1[1] / 7,
                    width: 
                        item.equipo1[0] === 0
                          ? "0%"
                          : (item.equipo1[1] * 100) /
                              (item.equipo1[1] + item.equipo2[1]) -
                            ((item.equipo1[1] * 100) /
                              (item.equipo1[1] + item.equipo2[1]) -
                              (100 * item.equipo1[0]) / item.equipo1[1]) +
                            "%",
                    backgroundColor: "green",
                    zIndex: 2,
                  }}
                  >
                  </div>
                </div>
              </p>
            </div>

            <div className="graficoE2">
              <p className="value">
                {item.equipo2[0]}/{item.equipo2[1]}
              </p>
              <div
                style={{

                  width:
                    item.equipo2[1] === 0
                      ? "0%"
                      : (item.equipo2[1] * 100) /
                          (item.equipo1[1] + item.equipo2[1]) /
                          2 +
                        "%",
                  borderTopRightRadius:
                    item.equipo2[1] === 0
                      ? 0
                      : (item.equipo2[1] * 100) /
                        (item.equipo1[1] + item.equipo2[1]) /
                        5,
                  borderBottomRightRadius:
                    item.equipo2[1] === 0
                      ? 0
                      : (item.equipo2[1] * 100) /
                        (item.equipo1[1] + item.equipo2[1]) /
                        5,
                  backgroundColor: "#FFD079",
                }}
                className="visual"
              >
                 <div
                  style={{
                    transition: "0.3s cubic-bezier(0.79, 0, 0.28, 1)",
                    height: "100%",
                    borderTopRightRadius: 
                        item.equipo2[1] === 0
                          ? 0
                          : (100 * item.equipo2[0]) / item.equipo2[1] / 6,

                    borderBottomRightRadius:
                        item.equipo2[1] === 0
                          ? 0
                          : (100 * item.equipo2[0]) / item.equipo2[1] / 6,
                    width: 
                        item.equipo1[0] === 0
                          ? "0%"
                          : (item.equipo2[1] * 100) /
                              (item.equipo1[1] + item.equipo2[1]) -
                            ((item.equipo2[1] * 100) /
                              (item.equipo1[1] + item.equipo2[1]) -
                              (100 * item.equipo2[0]) / item.equipo2[1]) +
                            "%",
                    backgroundColor: "orange",
                    zIndex: 2,
                  }}
                  >
                    
                  </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grafico">
            <p className="graphTypeName">{item.name}</p>
            <div className="graficoE1">
              <p className="value">{item.equipo1}</p>
              <div style={{
                  width:
                    item.equipo1 === 0
                      ? "0%"
                      : (item.equipo1 * 100) /
                          (item.equipo1 + item.equipo2) +
                        "%",
                  borderTopRightRadius:
                    item.equipo1 === 0
                      ? 0
                      : (item.equipo1 * 100) /
                        (item.equipo1 + item.equipo2) /
                        5,
                  borderBottomRightRadius:
                    item.equipo1 === 0
                      ? 0
                      : (item.equipo1 * 100) /
                        (item.equipo1 + item.equipo2) /
                        5,
                }} className="visual"></div>
            </div>

            <div className="graficoE2">
              <p className="value">{item.equipo2}</p>
              <div
                style={{
                  width:
                    item.equipo2 === 0
                      ? "0%"
                      : (item.equipo2 * 100) /
                          (item.equipo1 + item.equipo2) /
                          2 +
                        "%",
                  borderTopRightRadius:
                    item.equipo2 === 0
                      ? 0
                      : (item.equipo2 * 100) /
                        (item.equipo1 + item.equipo2) /
                        5,
                  borderBottomRightRadius:
                    item.equipo2 === 0
                      ? 0
                      : (item.equipo2 * 100) /
                        (item.equipo1 + item.equipo2) /
                        5,
                }}
                className="visual"
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GraficoInfo;
